<template>
  <div class="box" v-if="isShow">
    <img src="../static/img/warn.png" style="height: 40%;width: 20%;margin-top: 120px;position: absolute">
    <el-button style="background-color: #545252;margin-top: -35%;margin-left: 90%;position: absolute" circle @click="hide"></el-button>
    <h3 style="color: #da1010;font-family: '黑体';font-weight: bold;font-size: 25px;margin-top:-150px;position: absolute">{{title}}</h3>
    <h3 class="box-content">设备1:{{message}}</h3>
    <audio autoplay src="../static/audio/warn.mp3"></audio>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    duration: {
      type: Number,
      default: 1000 //默认1000毫秒消失
    }
  },
  data() {
    return {
      isShow: false //是否显示标识
    };
  },
  methods: {
    show() {
      this.isShow = true;
      setTimeout(this.hide, this.duration);//设置时间自动消失
    },
    hide() {
      this.isShow = false;
      this.$root.showwarn=false;
      console.log("alert:"+this.$root.showwarn);
      this.remove();//移除
    }
  },
  mounted() {
    this.show()
  }
};
</script>

<style>
.box {
  position: fixed;
  width: 30%;
  height: 25%;
  top: 30%;
  left: 35%;
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #edeff8;
  border: #da1010 2px solid;
  box-sizing: border-box;
}
.box-content {
  width: 200px;
  margin-top: -30px;
  font-size: 20px;
  font-family: "Times New Roman";
  padding: 8px 16px;
  color: black;
  border-radius: 3px;
  margin-bottom: 8px;
}
</style>