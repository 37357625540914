import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import Resource from 'vue-resource'
import './styles/common.scss';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import axios from "axios";
import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import BaiduMap from 'vue-baidu-map'
import ECharts from 'vue-echarts'
import Create from './util/create.js';
import Alert from './components/Alert.vue';
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/radar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/chart/graph'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title.js'
import scroll from 'vue-seamless-scroll'
import "lib-flexible"
import VueFullscreen from 'vue-fullscreen'

window.eventBus=new Vue()
Vue.use(VueFullscreen)
Vue.use(scroll)
// import 'echarts-gl'
// import 'babel-polyfill'
Vue.config.productionTip = false
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'XU8pwlyFQKLtGHat8Fb98cbCh3ddCz2w'
})
Vue.component('v-chart', ECharts)
Vue.use(ElementUI)
Vue.use(Resource)

// Vue.use(FlatSurfaceShader)
Vue.prototype.$axios=axios;
//Vue.prototype.$httpUrl='http://localhost:8080'
Vue.prototype.$httpUrl='http://47.115.228.233:8080'
Vue.prototype.$showwarn=false
Vue.prototype.$upperlimit=0
Vue.prototype.$currentdata=0
Vue.prototype.$Alert= ((obj)=>{//挂载在原型上
  return Create(Alert,obj).show(); //返回组件实例
})

Vue.config.productionTip = false
//Vue.use(ElementUI);
Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

